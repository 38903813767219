import * as React from "react";

import FreeTrialComponent from "../components/free-trial/free_trial";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const FreeTrial = () => {
  const { t } = useTranslation();
  return (
    <>
      <Seo
        title={`${t("free_trials")}`}
        description={`${t("free_trials")}`}
      />
      <Layout>
        <FreeTrialComponent />
      </Layout>
    </>
  );
};

export default FreeTrial;
