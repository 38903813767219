import React from "react";

export default class FreeTrialHBSPT extends React.Component {
    componentDidMount() {
        const formId = 'c8bf1ba0-d6c0-4452-9dfc-9920126e8db8';

        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            if(window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '20290290',
                    formId,
                    cssRequired: '',
                    target: '#FreeTrialHBSPT',
                    locale: 'fr',
                    translations: {
                        fr: {
                            forbiddenEmailDomainGeneric: "Veuillez utiliser votre adresse mail professionnelle",
                            forbiddenEmailDomain: "Veuillez utiliser votre adresse mail professionnelle",
                        }
                    }
                });
            }
        });

        window.addEventListener('message', event => {
            let innerDoc;
            const getIframeDoc = () => {
                const iframe = document.querySelector('#FreeTrialHBSPT > iframe');
                innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            }

            const formResultsContainer = document.querySelector('.trialFormStatic .trial_results_container');

            const formMessagesDuplicator = function () {
                getIframeDoc();

                const issuesList = innerDoc.querySelectorAll('.hs_error_rollup .hs-error-msgs');
                const issuesListFields = innerDoc.querySelectorAll('.hs-form-field .hs-error-msgs');
                const successMessage = innerDoc.querySelector('.submitted-message');

                if (issuesList.length || issuesListFields.length) {
                    formResultsContainer.innerHTML = '';
                    issuesList.forEach((issue) => {
                        formResultsContainer.innerHTML += issue.outerHTML;
                    });
                    if (issuesListFields.length <= 2) {
                        issuesListFields.forEach((issue) => {
                            formResultsContainer.innerHTML += issue.outerHTML;
                        });
                    }
                } else if (successMessage) {
                    formResultsContainer.innerHTML = successMessage.outerHTML;

                    const hideForm = document.querySelectorAll('.trial_static_hide');
                    hideForm.forEach((item) => {
                        item.style.display = 'none';
                    })
                } else {
                    formResultsContainer.innerHTML = '';
                }
            }
            const contactForm = document.querySelector(".trialFormStatic");
            const inputsList = contactForm.querySelectorAll('input, select, textarea');

            const buttonStatic = contactForm.querySelectorAll('.trial_submit_static');
            if (event.data.type === 'hsFormCallback' && event.data.id === formId) {
                if (event.data.eventName === 'onFormReady') {
                    getIframeDoc();

                    const doEvent = ( obj, name ) => {
                        const event = new Event( name, { target: obj, bubbles: true });
                        return obj ? obj.dispatchEvent(event) : false;
                    }

                    const formHbspt = innerDoc.querySelector('form');

                    inputsList.forEach(function (inputItem) {
                        const inputHbspt = innerDoc.querySelector('.hs-form-field *[name="' + inputItem.attributes.name.value + '"]');

                        inputItem.addEventListener('keyup', () => {
                            inputHbspt.value = inputItem.value;
                            doEvent(inputHbspt, 'change');
                            doEvent(inputHbspt, 'input');
                            doEvent(inputHbspt, 'blur');
                        });

                        inputItem.addEventListener('blur', () => {
                            inputHbspt.value = inputItem.value;
                            doEvent(inputHbspt, 'change');
                            doEvent(inputHbspt, 'input');
                            doEvent(inputHbspt, 'blur');
                        });

                        inputItem.addEventListener('change', () => {
                            inputHbspt.value = inputItem.value;
                            doEvent(inputHbspt, 'change');
                            doEvent(inputHbspt, 'input');
                            doEvent(inputHbspt, 'blur');
                        });
                    });
                    

                    buttonStatic.forEach((item) => {
                        item.addEventListener('click', () => {
                            doEvent(formHbspt, 'submit');
                            setTimeout(formMessagesDuplicator, 20);
                        });
                    });
                } else if (event.data.eventName === 'onFormSubmitted') {
                    formMessagesDuplicator();
                }
            }
        });
    }

    render() {
        return (
            <>
                <div style={{opacity: 0, visibility: "hidden", top: 0, position: "absolute"}}
                    id="FreeTrialHBSPT"></div>
            </>
        );
    }
}