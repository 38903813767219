import React from "react";
import Form from "./trial-form";
import Help from "../help/help";
import FreeTrialHBSPT from "./free_trial_hbspt";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const FreeTrial = () => {
  const breakpoints = useBreakpoint();
  return (
    <>
      {" "}
      <div className={`conatiner font-sans`}>
        <div
          className={`  bg-no-repeat xl:h-screen `}
          style={{ backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/bg1.svg")` }}
        >
          <div className=" flex flex-col-reverse md:flex-row  lg:justify-between items-center w-11/12 xl:w-4/6 mx-auto pt-20">
            <div className={`w-full lg:w-1/2  mx-auto md:text-white`}>
              <div
                className={`hidden md:flex text-sm md:text-lg font-extralight text-center md:text-left :my-3 `}
              >
                <h1>TESTEZ NOS SOLUTIONS</h1>
              </div>
              <div
                className={`hidden md:flex my-3 text-4xl text-center font-light md:text-left space-x-2`}
              >
                <span className="font-bold">Essai</span>
                <span>Gratuit</span>
              </div>
              <div className="">
                <div
                  className={`text-lg font-extralight text-center md:text-left mx-auto md:mx-0 font-normal w-11/12  lg:w-3/4 opacity-50 my-14 md:my-0`}
                >
                  <p>
                    Essayez la plateforme tout-en-un Mereos pour découvrir
                    comment nous simplifions la gestion de vos campagnes de
                    recrutement, contribuons à réduire vos coûts, fidélisons vos
                    candidats et facilitons le travail de vos équipes.
                  </p>

                  <p className="mb-0">
                    Aucune carte bancaire n’est requise. Notre essai gratuit
                    fonctionne sans engagement <br/> et vous permet de découvrir :
                  </p>
                </div>
                <div className={`w-full flex justify-start`}>
                  <nav className="list-none  font-medium   md:my-5">
                    <li className="flex flex-row space-x-2 lg:space-x-4">
                      <img
                        alt="tick icon"
                        src={`${
                          breakpoints.md
                            ? "https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon.svg"
                            : "https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        }`}
                      />{" "}
                      <span>L’ensemble de nos fonctionnalités sécuritaires</span>
                    </li>
                    <li className="flex flex-row space-x-2 lg:space-x-4">
                      <img
                        alt="tick icon"
                        src={`${
                          breakpoints.md
                            ? "https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon.svg"
                            : "https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        }`}
                      />{" "}
                      <span>
                      L’expérience vécue par les administrateurs
                      </span>
                    </li>
                    <li className="flex flex-row space-x-2 lg:space-x-4">
                      <img
                        alt="tick icon"
                        src={`${
                          breakpoints.md
                            ? "https://d2lxkizvrhu4im.cloudfront.net/icons/tick_icon.svg"
                            : "https://d2lxkizvrhu4im.cloudfront.net/icons/white_tick_icon.svg"
                        }`}
                      />
                      <span>L’expérience vécue par les candidats</span>
                    </li>
                  </nav>
                </div>
              </div>
            </div>
            <div className={`w-full flex flex-col items-center lg:w-1/2`}>
              <Form />
              {/* <FreeTrialHBSPT /> */}
            </div>
          </div>
        </div>

        <div className="w-11/12 md:w-2/3 mx-auto md:w-2/3 lg:w-5/6 md:my-20">
          <div className="md:my-20 mb-20  mt-10  ">
            <Help />
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeTrial;
