import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import * as classes from "./free-trial.module.scss";

const TrialForm = () => {
  const {t} = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    lastName: '',
    fonction:'',
    message:'',
    phone:''
  });
  
  const [errors, setErrors] = useState({
    email: '',
    name: '',
    lastName: '',
    fonction:'',
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [showResponseMsg, setShowResponseMsg] = useState('');

  const validateForm = () => {
    const hasErrors = Object.values(errors).some(error => error !== '');
    const hasEmptyFields = Object.values(formData).some(value => value === '');
    return !hasErrors && !hasEmptyFields;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData, errors]);

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };

    if (name === 'phone') {
        const phoneNumberLimit = 12; 
        const numericValue = value.replace(/[^0-9]/g, '');

        let formattedValue = value.startsWith('+') ? '+' : '';

        formattedValue += numericValue;

        if (numericValue.length > phoneNumberLimit) {
            newErrors.phone = `Phone number cannot exceed ${phoneNumberLimit} digits`;
        } else {
            newErrors.phone = '';
            setFormData({ ...formData, [name]: formattedValue });
        }
    } else {
        setFormData({ ...formData, [name]: value });
    }

    if (name === 'email') {
        if (!value) {
            newErrors.email = 'email_is_required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            newErrors.email = 'email_is_not_valid';
        } else {
            newErrors.email = '';
        }
    } else if (name !== 'phone') {
        if (!value) {
            newErrors[name] = 'this_field_is_required';
        } else {
            newErrors[name] = '';
        }
    }

    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowResponseMsg('please_wait');
    try {
      let personId;
      
      const searchResponse = await axios.get(
        'https://api.pipedrive.com/v1/persons/search',
        {
          params: {
            term: formData.email,
            fields: 'email',
            exact_match: true,
            api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
          }
        }
      );

      if (searchResponse.data.data.items.length > 0) {
        personId = searchResponse.data.data.items[0].item.id;
        console.log('Existing person found:', searchResponse.data.data.items[0].item);
      } else{
        const personResponse = await axios.post(
          'https://api.pipedrive.com/v1/persons',
          {
            name: `${formData.name} ${formData.lastName}`,
            email: formData.email,
            phone: formData.phone
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `8ce2444da077352e1e721778c927f15c93c3e7eb`
            },
            params: {
              api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
            }
          }
        );
        personId = personResponse.data.data.id;
      }

      const response = await axios.post(
        'https://api.pipedrive.com/v1/leads',
        {
          title:`Free Trial`,
          owner_id: 21122988,
          'aaef666f9241820e319fcb35c5f687cbee493760':formData.message,
          origin_id:'04a8a400-135a-11ef-b183-d34b4389e43d',
          person_id: personId,
          organization_id: 20,
          channel_id:'Free Trial',
          '2db5c7b3f7afb4006b3a79c6b44a4299c49a033a':formData.fonction
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `8ce2444da077352e1e721778c927f15c93c3e7eb`
          },
          params: {
            api_token: '8ce2444da077352e1e721778c927f15c93c3e7eb'
          }
        }
      );
  
      if(response){
        setShowResponseMsg('your_email_is_sent_successfully');
        setFormData({
          name: '',
          email: '',
          lastName: '',
          phone: '',
          message:'',
          fonction:''
        });
      }
    } catch (error) {
      setShowResponseMsg('something_went_wrong');
      console.error('Error creating deal:', error);
    }
  };
  
  return (
    <div className="bg-white text-center rounded-lg h-full  w-full shadow-lg pt-5 pb-10 px-10">
       <div className={`md:hidden flex justify-center text-lg font-bold text-center my-3 mx-auto `}>
       TESTEZ NOS SOLUTIONS
        </div>
        <div className={`md:hidden my-3 text-4xl text-center text-black font-light`}>
                <span className="font-bold">Essai</span> 
                <span>Gratuit</span>
              </div>
        <div className="hidden md:flex text-2xl font-bold text-center flex-row justify-center my-4 text-black">
        {t('free_trials')}
        </div>

        <div className="trialFormStatic">
        <div className="space-y-2">
      <div className={`${classes.trial_static_hide} flex flex-col items-start flex-col w-full md:space-x-2 space-y-2 md:space-y-0`}>
        <div className=" flex flex-row justify-start border-2 w-full   rounded-md items-center  py-1">
          <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg" className="m-3" />
          <input
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder={t("first_name")}
            className="rounded-2xl w-full h-full focus:outline-none text-black p-2"
          />
        </div>
        {errors.name && <span className='text-sm text-rose-600 mt-[7px]'>{t(errors.name)}</span>}
      </div>

      <div className={`${classes.trial_static_hide} flex flex-col items-start flex-col w-full md:space-x-2 space-y-2 md:space-y-0`}>
        <div className=" flex flex-row justify-start border-2 w-full   rounded-md items-center  py-1">
          <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg" className="m-3" />
          <input
            value={formData.lastName}
            name="lastName"
            onChange={handleChange}
            placeholder={t("last_name")}
            className="rounded-2xl w-full h-full focus:outline-none text-black p-2"
          />
        </div>
        {errors.lastName && <span className='text-sm text-rose-600 mt-[7px]'>{t(errors.lastName)}</span>}
      </div>

      <div className={`${classes.trial_static_hide} flex flex-col items-start flex-col w-full md:space-x-2 space-y-2 md:space-y-0`}>
        <div className=" flex flex-row justify-start border-2 w-full  rounded-md items-center  py-1">
          <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/message_icon.svg" className="m-3" />
          <input
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder={t('email')}
            className="rounded-2xl w-full h-full focus:outline-none text-black p-2"
          />
        </div>
        {errors.email && <span className='text-sm text-rose-700 mt-[7px]'>{t(errors.email)}</span>}
      </div>

      <div className="trial_static_hide flex flex-col md:flex-row w-full md:space-x-2 space-y-2 md:space-y-0">
        <div className=" flex flex-row justify-start border-2 w-full   rounded-md items-center  py-1">
          <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg" className="m-3" />
          <input
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder={t('phone_number')}
            className="rounded-2xl w-full h-full focus:outline-none text-black p-2"
          />
        </div>
      </div>

      <div className={`${classes.trial_static_hide} flex flex-col items-start flex-col w-full md:space-x-2 space-y-2 md:space-y-0`}>
        <div className=" flex flex-row justify-start border-2 w-full   rounded-md items-center  py-1">
          <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_icon.svg" className="m-3" />
          <select
            name="fonction"
            style={{appearence: "none"}}
            value={formData.fonction}
            onChange={handleChange}
            placeholder={t('function')}
            className="rounded-2xl w-full h-full text-sm focus:outline-none text-black p-2"
          >
            <option value="">Fonction</option>
            <option value="15">Directeur Général</option>
            <option value="16">Directeur Technique</option>
            <option value="17">Recruteur</option>
            <option value="18">Doyen, Proviseur, Enseignant</option>
            <option value="19">Responsable Opérationnel</option>
            <option value="20">Administrateur LMS</option>
            <option value="21">Autre</option>
          </select>
        </div>
        {errors.fonction && <span className='text-sm text-rose-600 mt-[7px]'>{t(errors.fonction)}</span>}
      </div>

      <div className="trial_static_hide flex flex-row justify-start border-2 w-full rounded-md items-start  py-1">
        <img alt="data form" src="https://d2lxkizvrhu4im.cloudfront.net/icons/edit_icon.svg" className="m-3" />
        <textarea
          name="message"
          placeholder="Message (optionnel)"
          className="rounded-2xl w-full h-full focus:outline-none text-black p-2 resize-none"
          rows="5"
          value={formData.message}
          onChange={handleChange}
        />
      </div>

      <div className={`${classes.trial_static_hide}`}>
        <button 
          onClick={handleSubmit}
          disabled={(!isFormValid || showResponseMsg === t('please_wait'))}
          className="trial_submit_static flex flex-row justify-center bg-yellow-300 w-full rounded-md items-center space-x-3 py-4 focus:outline-none"
        >
          <img alt="header" src="https://d2lxkizvrhu4im.cloudfront.net/icons/send_icon.svg" className="my-1 h-4" />
          <p className="text-black font-bold text-xl mb-0">{t('send')}</p>
        </button>
        <p style={{color:'black'}}>{t(showResponseMsg)}</p>
      </div>
      <div className="trial_results_container text-xl font-semibold text-black"></div>
    </div>
        </div>
      </div>
  );
};

export default TrialForm;
